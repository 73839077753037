import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { down } from 'styled-breakpoints';
import Img from 'gatsby-image';

const Title = styled.h3`
  font-size: 44px;
  line-height: 54px;
  font-weight: 700;
  display: block;
`;

const ServicesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  ${down('md')} {
    justify-content: center;
  }

  .gatsby-image-wrapper {
    width: 140px;
    height: 90px;
    display: inline-block;
    margin: 0 20px 20px;
    opacity: 1;

    &:hover {
      opacity: 0.9;
    }

    ${down('md')} {
      margin: 0 10px 30px;
    }
  }
`;

const StyledOurClients = styled.section`
  padding-top: 7.42857143em !important;
`;

const OurClients = () => {
  const data = useStaticQuery(graphql`
    query OurClientImages {
      allFile(filter: { sourceInstanceName: { eq: "clients" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);

  const {
    allFile: { edges }
  } = data;

  return (
    <StyledOurClients>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <Title>Algunos de los clientes que han confiado en nosotros</Title>
          </div>

          <div className="col-md-8">
            <ServicesGrid>
              {edges.map(({ node }, key) => (
                // <img
                //   key={key}
                //   src={node.childImageSharp.fluid.src}
                //   srcSet={node.childImageSharp.fluid.srcSet}
                // />
                <Img fluid={node.childImageSharp.fluid} alt={node.name} key={key} />
              ))}
              {/*<img*/}
              {/*  src={*/}
              {/*    'http://themenectar.com/demo/salient-corporate-3/wp-content/uploads/2019/09/ollo.jpg'*/}
              {/*  }*/}
              {/*/>*/}
            </ServicesGrid>
          </div>
        </div>
      </div>
    </StyledOurClients>
  );
};

export default OurClients;
