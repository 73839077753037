import React from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const BrandLogos = styled.div`
  display: flex;
  justify-content: space-between;

  ${down('md')} {
    overflow-x: auto;
    justify-content: left;
  }
`;

const StyledOurBrands = styled.div`
  ${down('md')} {
    margin-top: 40px;
  }

  h4 {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    color: white;
    opacity: 0.3;
    margin-left: 20px;
    text-align: left;

    ${down('md')} {
      color: black;
      margin-bottom: 0;
      margin-top: 20px;
    }
  }

  .gatsby-image-wrapper {
    width: 120px;
    filter: brightness(0) invert(1);
    margin-bottom: 0;
    opacity: 0.3;

    ${down('md')} {
      filter: grayscale(1);
      max-width: 100px;
    }
  }
`;

const OurBrands = () => {
  const data = useStaticQuery(graphql`
    query OurBrandsImages {
      allFile(filter: { sourceInstanceName: { eq: "brands" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 120) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);

  const {
    allFile: { edges }
  } = data;

  const warrantyLogos = edges.filter(({ node }) => node.name.includes('ga'));
  const brandsLogos = edges.filter(({ node }) => !node.name.includes('ga'));

  return (
    <StyledOurBrands>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h4>Marcas recomendadas</h4>

            <BrandLogos>
              {brandsLogos.map(({ name, node }, key) => (
                <Img fluid={node.childImageSharp.fluid} alt={`${name} logo`} key={key} />
              ))}
            </BrandLogos>
          </div>

          <div className="col-md-3 offset-md-1">
            <h4>Garantía</h4>

            <BrandLogos>
              {warrantyLogos.map(({ name, node }, key) => (
                <Img fluid={node.childImageSharp.fluid} alt={`${name} logo`} key={key} />
              ))}
            </BrandLogos>
          </div>
        </div>
      </div>
    </StyledOurBrands>
  );
};

export default OurBrands;
