import React from 'react';
import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const StyledServiceContent = styled.div`
  position: absolute;
  right: 0;
  margin: 50px 20px 20px;
  z-index: 2;

  ${down('md')} {
    margin: 25px 0 0 20px;
  }

  ${({ type }) =>
    type === 'mid' &&
    css`
      left: 0;
      margin-top: 15px;

      ${StyledServiceTitle} {
        font-size: 30px;
      }

      ${StyledServiceSubTitle} {
        font-size: 15px;
      }
    `}

  ${({ align }) => align === 'right' && 'left: auto'};

  ${({ color }) =>
    color === 'secondary' &&
    css`
      ${StyledServiceTitle},
      ${StyledServiceSubTitle} {
        color: #333333;
      }
    `}
`;

const StyledBgService = styled.div`
  box-shadow: 0 60px 135px rgba(0, 0, 0, 0.14), 0 15px 65px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  background-color: ${({ color }) => color};
  position: relative;
  width: 100%;
  height: ${({ height }) => height};

  .gatsby-image-wrapper {
    border-radius: 6px;
    height: ${({ height }) => height};
  }

  ${down('md')} {
    height: 350px !important;

    .gatsby-image-wrapper {
      height: 350px !important;
    }
  }
`;

const StyledServiceTitle = styled.h2`
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  font-size: 40px;
  margin-bottom: 0;

  ${down('md')} {
    font-size: 20px !important;
  }
`;

const StyledServiceSubTitle = styled.h3`
  line-height: 1.1;
  color: white;
  font-weight: 500;
  font-size: 20px;
  width: 80%;

  ${down('md')} {
    font-size: 14px !important;
  }
`;

const StyledServiceLink = styled.span`
  color: white !important;
  border-bottom: 1px solid #fff;
  padding-bottom: 2px;

  padding: 5px 30px;
  border-radius: 6px;
  background: white;
  color: #333 !important;
  //bottom: -240px;
  font-size: 12px;

  position: absolute;
  z-index: 2;
  bottom: 15px;
  right: 15px;
`;

const StyledService = styled(({ product, ...props }) => <Link to={`/${product}`} {...props} />)`
  position: relative;
  display: block;

  ${StyledServiceTitle} {
    ${({ size }) => size === 'medium' && 'font-size: 30px'};
    ${({ color }) => color === 'secondary' && 'color: #333'};
  }

  ${StyledServiceSubTitle} {
    ${({ size }) => size === 'medium' && 'font-size: 20px'};
    ${({ color }) => color === 'secondary' && 'color: #333'};
  }

  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:hover {
    transform: scale(1.03, 1.03);
  }

  &::after {
    opacity: 1;
  }
`;

const StyledServiceHeader = styled.div`
  text-align: center;

  h3 {
    font-size: 50px;
    margin-bottom: 10px;
    text-align: center;

    ${down('md')} {
      line-height: 1;
      font-size: 40px;
    }

    span {
      line-height: 1.3;
      display: inline-block;
      position: relative;

      &:before {
        content: '';
        background: linear-gradient(
          -74deg,
          rgb(35, 175, 186) 0%,
          rgb(41, 141, 191) 68.55%,
          rgb(48, 107, 196) 100%
        );
        width: 100%;
        height: 6px;
        position: absolute;
        transform: scaleX(1);
        display: block;
        left: 0;
        top: auto;
        bottom: 7px;
        border-radius: 4px;
        z-index: -1;
      }

      ${down('md')} {
        line-height: 1;
        &:before {
          bottom: 0;
        }
      }
    }
  }

  p {
    font-size: 20px;
    width: 50%;
    text-align: center;
    margin: 0 auto;

    ${down('md')} {
      width: 100%;
    }
  }
`;

const StyledServiceList = styled.div`
  .block {
    margin-top: 30px;
  }

  ${down('md')} {
    .block {
      margin-top: 0;
    }

    ${StyledService} {
      margin-top: 30px;
    }
  }
`;

const getServiceBySlug = (services = [], slugName) =>
  services.find(({ slug }) => slug === slugName) || {};

const Services = () => {
  const data = useStaticQuery(graphql`
    query HomeService {
      allContentfulService {
        nodes {
          slug
          order
          title
          shortDescription
          indexImage {
            fluid(maxWidth: 850) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  `);
  const services = data.allContentfulService.nodes;
  const banderas = getServiceBySlug(services, 'banderas');
  const carteleria = getServiceBySlug(services, 'carteleria');
  const rotulos = getServiceBySlug(services, 'rotulos');
  const merchandising = getServiceBySlug(services, 'merchandising');
  const rotulacionVehiculos = getServiceBySlug(services, 'rotulacion-vehiculos');
  const vinilos = getServiceBySlug(services, 'vinilos');
  const lonas = getServiceBySlug(services, 'lonas');

  return (
    <>
      <section>
        <div className="container">
          <StyledServiceHeader>
            <h3>
              Nuestros <span>Servicios</span>
            </h3>
            <p>
              Ofrecemos soluciones personalizadas con la mejor calidad de impresión y excelentes
              acabados.
            </p>
          </StyledServiceHeader>
        </div>
      </section>

      <section>
        <StyledServiceList className="container-fluid">
          <div className="row">
            <div className="col-md-5">
              <div className="row">
                <div className="col-md-12">
                  <StyledService product={banderas.slug}>
                    <StyledServiceContent>
                      <StyledServiceTitle>{banderas.title}</StyledServiceTitle>

                      <StyledServiceSubTitle>{banderas.shortDescription}</StyledServiceSubTitle>
                    </StyledServiceContent>

                    <StyledServiceLink>Ver servicio</StyledServiceLink>

                    <StyledBgService height={'520px'} color={'#577590'}>
                      <Img fluid={banderas.indexImage.fluid} />
                    </StyledBgService>
                  </StyledService>
                </div>

                <div className="block col-md-12">
                  <StyledService product={vinilos.slug}>
                    <StyledServiceContent>
                      <StyledServiceTitle>{vinilos.title}</StyledServiceTitle>

                      <StyledServiceSubTitle>{vinilos.shortDescription}</StyledServiceSubTitle>
                    </StyledServiceContent>

                    <StyledServiceLink>Ver servicio</StyledServiceLink>

                    <StyledBgService height={'350px'} color={'#1E3D58'}>
                      <Img fluid={vinilos.indexImage.fluid} />
                    </StyledBgService>
                  </StyledService>
                </div>
              </div>
            </div>

            <div className="col-md-7">
              <div className="row">
                <div className="col-md-6">
                  <StyledService product={carteleria.slug}>
                    <StyledServiceContent type={'mid'} align={'right'}>
                      <StyledServiceTitle>{carteleria.title}</StyledServiceTitle>

                      <StyledServiceSubTitle>{carteleria.shortDescription}</StyledServiceSubTitle>
                    </StyledServiceContent>

                    <StyledServiceLink>Ver servicio</StyledServiceLink>

                    <StyledBgService height={'350px'} color={'#43AA8B'}>
                      <Img fluid={carteleria.indexImage.fluid} />
                    </StyledBgService>
                  </StyledService>
                </div>

                <div className="col-md-6">
                  <StyledService product={merchandising.slug}>
                    <StyledServiceContent type={'mid'}>
                      <StyledServiceTitle>{merchandising.title}</StyledServiceTitle>

                      <StyledServiceSubTitle>
                        {merchandising.shortDescription}
                      </StyledServiceSubTitle>
                    </StyledServiceContent>

                    <StyledServiceLink>Ver servicio</StyledServiceLink>

                    <StyledBgService height={'350px'} color={'#F9C74F'}>
                      <Img fluid={merchandising.indexImage.fluid} />
                    </StyledBgService>
                  </StyledService>
                </div>
              </div>

              <div className="row">
                <div className="block col-md-12">
                  <StyledService product={rotulacionVehiculos.slug}>
                    <StyledServiceContent type={'mid'} color={'secondary'}>
                      <StyledServiceTitle>{rotulacionVehiculos.title}</StyledServiceTitle>

                      <StyledServiceSubTitle>
                        {rotulacionVehiculos.shortDescription}
                      </StyledServiceSubTitle>
                    </StyledServiceContent>

                    <StyledServiceLink>Ver servicio</StyledServiceLink>

                    <StyledBgService height={'350px'} color={'#edf2f4'}>
                      <Img fluid={rotulacionVehiculos.indexImage.fluid} />
                    </StyledBgService>
                  </StyledService>
                </div>
              </div>

              <div className="row">
                <div className="block col-md-6">
                  <StyledService product={rotulos.slug}>
                    <StyledServiceContent type={'mid'}>
                      <StyledServiceTitle>{rotulos.title}</StyledServiceTitle>

                      <StyledServiceSubTitle>{rotulos.shortDescription}</StyledServiceSubTitle>
                    </StyledServiceContent>

                    <StyledServiceLink>Ver servicio</StyledServiceLink>

                    <StyledBgService height={'350px'} color={'#e94560'}>
                      <Img fluid={rotulos.indexImage.fluid} />
                    </StyledBgService>
                  </StyledService>
                </div>

                <div className="block col-md-6">
                  <StyledService product={lonas.slug}>
                    <StyledServiceContent type={'mid'}>
                      <StyledServiceTitle>{lonas.title}</StyledServiceTitle>

                      <StyledServiceSubTitle>{lonas.shortDescription}</StyledServiceSubTitle>
                    </StyledServiceContent>

                    <StyledServiceLink>Ver servicio</StyledServiceLink>

                    <StyledBgService height={'350px'} color={'#e8ded2'}>
                      <Img fluid={lonas.indexImage.fluid} />
                    </StyledBgService>
                  </StyledService>
                </div>
              </div>
            </div>
          </div>
        </StyledServiceList>
      </section>
    </>
  );
};

export default Services;
