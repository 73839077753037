import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Contact from '../components/Home/Contact';
import Claim from '../components/Home/Claim';
import Services from '../components/Home/Services';
import OurClients from '../components/Service/OurClients';
import OurBrands from '../components/Home/OurBrands';

const IndexPage = ({ data }) => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'home' })} />
      <Claim />

      <div className="main-container">
        <div className="visible-xs">
          <OurBrands />
        </div>

        <Services />

        <OurClients />

        <Contact />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ContentFul {
    allContentfulServices {
      nodes {
        services {
          id
          slug
          order
          title
          shortDescription
        }
      }
    }
  }
`;

export default IndexPage;
