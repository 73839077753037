import React from 'react';
import styled from 'styled-components';
import { CONSTANT } from '../../../constants';
import { down } from 'styled-breakpoints';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const StyledContact = styled.section`
  background: #f7f7f7;

  .container-fluid {
    position: relative;
    padding: 50px 0;
  }

  ${down('md')} {
    .container-fluid {
      padding: 0;
    }

    .contact-details {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
`;

const StyledMap = styled.div`
  overflow: hidden;
`;

const StyledBgMap = styled.div`
  position: absolute;
  background: #fdfdfd;
  left: 0;
  width: 50%;
  height: 100%;

  ${down('md')} {
    display: none;
  }
`;

const Contact = () => {
  const data = useStaticQuery(graphql`
    query MapImage {
      allFile(filter: { name: { eq: "map-contact-footer" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 800, srcSetBreakpoints: 300) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);

  const {
    allFile: {
      edges: [image]
    }
  } = data;

  return (
    <StyledContact id="contacto" className="imageblock switchable feature-large bg--white">
      <StyledBgMap />

      <div className="container-fluid">
        <StyledMap className="imageblock__content col-lg-6 col-md-4 pos-right">
          <a href={CONSTANT.BUSINESS_GOOGLE_MAP_URL} target="_blank">
            <Img fluid={image.node.childImageSharp.fluid} alt="Mapa ALM Print" />
          </a>
        </StyledMap>

        <div className="container">
          <div className="row">
            <div className="contact-details col-lg-5 col-md-7">
              <h3>
                Calle Hermanos Lumiere
                <br />
                Polígono El Cañal, 5
              </h3>
              <h4>Arganda del Rey, Madrid</h4>
              <p className="lead">
                Correo electrónico: <a href="mailto:info@almprint.es">info@almprint.es</a>
                <br /> Teléfono: <a href="tel:918729809">918 72 98 09</a>
              </p>
              <p className="lead">
                Llámenos o visítenos en cualquier momento, nos esforzamos por responder todas las
                consultas en un plazo máximo 24 horas.
              </p>
              <p className="lead">Estamos abiertos desde las 9:00 a 14:00 - 15:30 a 18:00</p>
            </div>
          </div>
        </div>
      </div>
    </StyledContact>
  );
};

export default Contact;
